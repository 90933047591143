import React, { useState } from 'react'
import Styled from 'styled-components'

// Content

const storyContent = {
    0: {
        title: 'Stay in control of your recurring payments',
        description:
            'Easily schedule payments, recurring transfers or direct debits – and track them all in one place',
        image: '/images/story-1.png',
    },
    1: {
        title: 'Stay on top!',
        description: 'With a short description',
        image: '/images/story-2.png',
    },
    2: {
        title: 'No surprises with payment reminders',
        description:
            'Get reminders before upcoming scheduled payments, to stay in control of your spending.',
        image: '/images/story-3.png',
    },
}

// Navbar

const StoryProgress = Styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: white;
    width: 98%;
    margin-right: 4px;
`

const NavBar = () => {
    return (
        <div style={{ paddingBottom: 24 }}>
            <div
                style={{
                    display: 'flex',
                    // alignItems: 'center',
                }}
            >
                <StoryProgress />
                <StoryProgress style={{ opacity: 0.3 }} />
            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 16,
                }}
            >
                <span>Product name</span>
                <span
                    className='material-icons'
                    style={{ fontSize: 20, opacity: 0.8, marginLeft: 'auto' }}
                >
                    close
                </span>
            </div>
        </div>
    )
}

// Header

const H1 = Styled.h1`
    font-size: 24px;
    line-height: 130%;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin: 0 0 16px 0;

    @media (min-height: 600px) { // Height!
        font-size: 28px;
        line-height: 130%;
    }

`

// Button

const CTA = Styled.button`
    margin-top: auto;
    height: 48px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    outline: none;
    border: none;
    cursor: pointer;
`

// Story navigation

const NavStoryArea = Styled.div`
    display: block;
    position: relative;
    background: white;
    height: 100%;
    width: 40%;
    opacity: 0;
    @media (min-width: 600px) {
        cursor: pointer;
        : hover {
            opacity: 0.04;
        }
        transition: all 300ms ease-out;
    }
    
`
const NavStory = (props) => {
    const styles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        width: '100%',
        height: '100%',
    }
    return (
        <div style={styles}>
            <NavStoryArea onClick={props.onClick} />
            <NavStoryArea onClick={props.onClick} />
        </div>
    )
}

const Overlay = Styled.div`
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(15, 19, 38, 0.9) 0%, rgba(15, 28, 40, 0.1) 40%, rgba(15, 28, 41, 0) 60%);
    
    
    @media (max-height: 640px) { // Tiny screens
        background: linear-gradient(180deg, rgba(15, 19, 38, 0.9) 0%, rgba(15, 28, 40, 0.5) 50%, rgba(15, 28, 41, 0) 100%);
    }
`

const Image = Styled.div`
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: #07598B;
    background-image: url(${(props) => props.image});
`

// Story

const Story = ({ className }) => {
    const [storyNum, setStoryNum] = useState(0)

    const handleClick = (e) => {
        const nextNum = storyContent[storyNum + 1] ? storyNum + 1 : 0
        setStoryNum(nextNum)
    }
    return (
        <div className={className}>
            <Image image={storyContent[storyNum].image} />
            <Overlay />
            <div
                className='Content'
                style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 24,
                    height: '100%',
                    width: '100%',
                }}
            >
                <NavBar />
                <div style={{ maxWidth: 440 }}>
                    <H1>{storyContent[storyNum].title}</H1>
                    <span
                        style={{
                            fontSize: 16,
                            lineHeight: '22px',
                            opacity: 0.8,
                        }}
                    >
                        {storyContent[storyNum].description}
                    </span>
                </div>
                <CTA>Get started</CTA>
            </div>
            <NavStory onClick={(e) => handleClick(e)} />
        </div>
    )
}

const StyledStory = Styled(Story)`
    display: block;
    position: relative;
    overflow: hidden;
    color: white;
    background-color: #f3f3f3;

    border-radius: 0;
    width: 100%;
    height: 100%;

    max-width: 100%;
    max-height: 100%;

    @media (min-width: 600px) {
        border-radius: 12px;
        max-width: 400px;
        max-height: 667px;
    }

    @media (max-height: 400px) {
        border-radius: 0;
        max-width: 100%;
        max-height: 100%;
    }
`

export default StyledStory

import Styled from 'styled-components'
import Story from './components/Story'

const Pattern = Styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f4f5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2338577a' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`

const Overlay = Styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.8);
    @media (min-width: 600px) {
      padding: 24px;
    }
    @media (max-height: 400px) {
      padding: 0;
    }
`

function App() {
    return (
        <div>
            <Pattern />
            <Overlay>
                <Story />
            </Overlay>
        </div>
    )
}

export default App
